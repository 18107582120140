@import '../theme-bootstrap';

.search-form {
  width: 100%;
  max-width: $search-max-width;
  margin: 0 auto;
  position: relative;
  @if $search_modernized_design == false {
    padding: 24px 10px;
  } @else {
    .search-results__header & .search-form__fields {
      margin-top: 30px;
      margin-inline-start: 5%;
    }
  }
  &__fields {
    @include pie-clearfix;
    position: relative;
    @if $search_modernized_design == true {
      margin: 16px 0;
      border: $color-off-black solid 1px;
      width: 90%;
      @include breakpoint($portrait-up) {
        margin: 16px 0;
        width: 95%;
      }
    } @else {
      border-bottom: $color-gray solid 1px;
    }
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
    border: none;
  }
  input[type='text'].search-form__field {
    line-height: get-line-height(18px, 24px);
    width: 100%;
    background: transparent;
    padding: 0;
    @if $search_modernized_design == true {
      color: $color-off-black;
      text-transform: none;
      font-size: 16px;
      height: 46px;
      font-weight: 400;
      padding: 12px 16px;
      @include breakpoint($portrait-up) {
        line-height: get-line-height(32px, 50px);
      }
      @include input-placeholder {
        color: $color-tmo-gray;
        font-size: 16px;
        height: 46px;
        text-transform: none;
        font-weight: 400;
      }
    } @else {
      color: $color-gray;
      font-size: 18px;
      height: auto;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        font-size: 32px;
        height: 45px;
        line-height: get-line-height(32px, 50px);
        @include input-placeholder {
          color: $color-gray;
          font-size: 32px;
          height: 45px;
        }
      }
    }
  }
  input[type='submit'].search-form__submit {
    height: 45px;
    line-height: get-line-height(15px, 45px);
    display: block;
    padding: 0;
    position: absolute;
    #{$rdirection}: 0;
    opacity: 0; // we hide the button with opacity, the icon is visible
    z-index: 2;
    top: -10px;
    #{$ldirection}: 5px;
    width: 24px;
    max-width: 24px;
    min-width: 24px;
    @if $search_modernized_design == true {
      top: -5px;
      #{$ldirection}: auto;
      #{$rdirection}: 5px;
      width: 36px;
      max-width: 36px;
      min-width: 36px;
    }
    @include breakpoint($portrait-up) {
      top: -5px;
      #{$ldirection}: auto;
      #{$rdirection}: 5px;
      width: 36px;
      max-width: 36px;
      min-width: 36px;
    }
  }
  .icon--search {
    position: absolute;
    @if $search_modernized_design == true {
      fill: $color-off-black;
      width: 18px;
      height: 18px;
      top: 15px;
      #{$rdirection}: 15px;
      @include breakpoint($portrait-up) {
        width: 18px;
        height: 18px;
      }
    } @else {
      fill: $color-gray;
      width: 24px;
      height: 24px;
      top: -2px;
      #{$rdirection}: 2px;
      @include breakpoint($portrait-up) {
        width: 36px;
        height: 36px;
      }
    }
  }
  .site-header__menu label#site-header_search {
    display: none;
  }
}
